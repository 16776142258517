<template>
    <b-row class="justify-content-center">
        <b-col md="6">
            <b-card>
                <b-form v-on:submit.prevent>
                    <b-row>
                        <b-col cols="12">
                            <b-form-group
                            label="Division Name"
                            label-for="divisions"
                            >
                                <b-form-input
                                    id="divisions"
                                    placeholder="Division Name"
                                    v-model="form.divisions"
                                    required
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                            label="Organization"
                            label-for="organizations"
                            >
                                <b-form-select
                                id="organizations"
                                v-model="selected"
                                :options="options"
                                required
                                />
                                <!-- <b-card-text class="mt-1 mb-0">
                                Selected: <strong>{{ selected }}</strong>
                                </b-card-text> -->
                            </b-form-group>
                        </b-col>

                        <!-- submit and reset -->
                        <b-col md="12" class="mt-2">
                            <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            class="mr-1"
                            @click="updateDivision()"
                            >
                            Update
                            </b-button>
                            <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="danger"
                            class="mr-1"
                            @click="deleteDivisions()"
                            >
                            Delete
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BFormSelect, BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        ToastificationContent,
        BFormSelect,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                divisions: ''
            },
            selected: null,
            options: []
        }
    },
    created() {
        this.$http.get('organizations')
        .then(res => { 
            this.options.push({value: null, text: 'Please select 1 organization'})
            
            var datas = res.data.data
            var i
            for(i=0; i<datas.length; i++) {
                var $data = {
                    value: datas[i].id,
                    text: datas[i].name
                }
                this.options.push($data)
            }
            // console.log(this.options)
        });

        const id = this.$route.params.id
        // console.log(id);
        this.$http
            .get('divisions/' +id)
            .then((response) => {
                this.form.divisions = response.data.data.name
                this.selected = response.data.data.organization_id
                // console.log(response.data.data)
            }).catch((err) => {
                console.log(err.response)
            });
    },
    methods: {
        updateDivision() {
            const id = this.$route.params.id
            // console.log(id);

            this.$http
            .post("divisions/"+id+"/update", {
                "name": this.form.divisions,
                "organization_id": this.selected
            })
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success update Division',
                        variant: 'success',
                    },
                })
                location.href = "/settings/divisions"
                // console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        deleteDivisions() {
            const id = this.$route.params.id
            this.$http
            .post("divisions/"+id+"/delete")
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Division has been deleted',
                        variant: 'danger',
                    },
                })
                location.href = "/settings/divisions"
                console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                console.log(errors.response)
            })
        },
    },
}
</script>